          <template>
            <div>
              <vue-element-loading
                :active="appLoading"
                :is-full-screen="true"
                background-color="#FFFFFF"
                color="#3F033B"
                spinner="bar-fade-scale"
              />
              <ServerError v-if="ServerError" />
              <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
                <v-layout wrap justify-center>
                  <v-flex text-left class="align-self-center">
                    <span style="color: white">
                      {{ msg }}
                    </span>
                  </v-flex>
          
                  <v-flex text-right>
                    <v-btn small :ripple="false" text @click="showSnackBar = false">
                      <v-icon style="color: #000">mdi-close</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-snackbar>
          <v-layout wrap>
          <v-flex xs12 sm6 md8 text-left>
            <span class="mainHeader">OUTBOUND LIST:</span>
          </v-flex>
          <v-flex xs12 sm3 md2 text-left pl-1 pt-1>
        <v-btn class="buttons1" dark color="#3F053C" block @click="$router.push('/AddAciding')"
          ><v-icon>mdi-plus</v-icon> New Batch</v-btn
        >
      </v-flex>
      <v-flex xs12 sm3 md2 text-left pl-1 pt-1>
        <v-btn class="buttons1" dark color="#3F053C" block @click="$router.push('/AcidingReport')"
          >Report</v-btn
        >
      </v-flex>
          </v-layout>
              <v-layout wrap>
                <v-flex xs12 py-4>
                  <v-card
                    tile
                    elevation="0"
                    class="rounded-lg pb-2"
                    :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
                    :flat="$route.name == 'Dashboard' ? true : false"
                  >
                  <v-layout px-4 py-4 wrap v-if="List">
                      <v-flex xs12 v-if="List.length>0">
                        <v-card class="pb-2" elevation="0">
                          <v-layout wrap>
                            <v-flex xs12 >
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead style="text-transform: uppercase">
                                    <tr>
                                      <th class="text-left tablefont">
                                        <b>Batch No.</b>
                                      </th>
                                      <th class="text-left tablefont"><b>Aciding Date</b></th>
                                      <!-- <th class="text-left tablefont" style="min-width: 20px;">
                                        <b>Gold Rate</b>
                                      </th> -->
                                      <th class="text-left tablefont"><b>Gr.wt(gm)</b></th>
                                      <th class="text-left tablefont"><b>Net.wt(gm)</b></th>
                                      <th class="text-left tablefont"><b>Acidifier</b></th>
                                      <!-- <th class="text-left tablefont"><b>Total Amt.(₹)</b></th> -->
                                      <th class="text-left tablefont"><b>Action</b></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(value, i) in List" :key="i" class="table">
                                    
                                      <td class="text-left table">
                                        <span v-if="value.batchNumber"
                                          >{{ value.batchNumber }}</span
                                        >
                                        <span v-else>-</span>
                                      </td>
                                      <td class="text-left table">
                                        <span v-if="value.batchDate">
                                          {{ formatDate(value.batchDate) }}</span
                                        >
                                        <span v-else>-</span>
                                      </td>
                                      <td class="text-left table">
                                        <span v-if="value.totalOldGoldGrossWeight">{{
                                          value.totalOldGoldGrossWeight
                                        }}</span>
                                        <span v-else>0</span>
                                      </td>
                                      <td class="text-left table">
                                        <span v-if="value.totalOldGoldNetWeight">{{
                                          value.totalOldGoldNetWeight
                                        }}</span>
                                        <span v-else>0</span>
                                      </td>
                                      <td class="text-left table">
                                        <!-- <span v-if="value.acidifier">{{
                                          value.acidifier.supplierName
                                        }}</span>
                                        <span v-else>-</span> -->
                                        <div v-if="value.acidifier">
                                <span v-if="value.acidifier.supplierName"
                                >{{ value.acidifier.supplierName }}</span
                              >
                              </div>
                              <div v-else>
                                <span v-if="value.acidifierType"
                                >{{ value.acidifierType }}</span
                              >
                                <span v-else>-</span>
                              </div>
                                      </td>
                                      <td>
                                        <v-btn
                              color="#3F053C"
                              dark
                              block
                              small class="buttons1"
                              @click="getAcidingDetails(value)"
                              >Acidify</v-btn
                            >
                                      </td>
                                      <!-- <td class="text-left tablefont2">
                                        <span v-if="value.totalMeltWeight">{{
                                          value.totalMeltWeight
                                        }}</span>
                                        <span v-else>0</span>
                                      </td> -->
                                      <!-- <td class="text-left tablefont2">
                                       <v-icon>mdi-eye</v-icon>
                                      </td> -->
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-flex>
                          </v-layout>
                        </v-card>
                       
                      </v-flex>
                      <v-flex xs12 v-else pa-4 text-center class="tablefont1">
                No data found
              </v-flex>
                    </v-layout>
                    <v-layout wrap v-if="List">
          <v-flex xs12 pt-4 v-if="List.length>0">
            <v-pagination
              small
              color="#3F053C"
              v-model="page"
              :length="Pagelength"
            >
            </v-pagination>
          </v-flex>
        </v-layout>
        <v-dialog width="800px" v-model="addAciding">
          <v-form v-model="addcat" ref="addcat" @submit.prevent>
            <v-card tile width="1000px" class="px-4 py-4 rounded-lg">
              <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">INBOUND</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                    @click="(addAciding = false)(resetForm())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm6>
                  <v-layout wrap pb-4>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont"> Batch Code</span>
                        </v-flex>
                        <v-flex xs12>
                <v-text-field
                v-model="batchNo" disabled
                     hide-details="auto"
                    type="number"
                    color="#b234a9"
                    outlined flat class="txtfield2"
                    placeholder="Batch Number"
                    dense
                  ></v-text-field
                >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Before Aciding(gm)</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                    v-model="beforeAciding"
                     hide-details="auto"
                    type="number" disabled
                    color="#b234a9"
                    outlined flat class="txtfield2"
                    placeholder="Before Aciding"
                    dense
                  ></v-text-field
                >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Aciding Date</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                      outlined flat class="txtfield2" dense
                        hide-details="" disabled
                        placeholder="Aciding Date"
                        v-model="acidingDate"
                      ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Test Piece(gm)</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                    @input="cal2()"
                    type="number"
                    v-model="testPiece"
                    outlined flat class="txtfield2"
                    color="#b234a9"
                    placeholder="Test Piece(gm)"
                    dense
                    hide-details
                  ></v-text-field
                >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Touch(%)</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                    type="number"
                    v-model="acidingTouchPercent"
                    outlined flat class="txtfield2"
                    color="#b234a9"
                    placeholder="Touch(%)"
                    dense
                    hide-details
                  ></v-text-field
                >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Returned Thankam</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                    v-model="afterAcidingWt"
                     hide-details="auto"
                    type="number" disabled
                    color="#b234a9"
                    outlined flat class="txtfield2"
                    placeholder="After Aciding Thankam"
                    dense
                  ></v-text-field
                >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-layout wrap pb-4>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Boarding Rate(₹)</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                    type="number"
                    v-model="acidingBoardingRate"
                    outlined flat class="txtfield2"
                    color="#b234a9"
                    placeholder="Boarding Rate "
                    dense
                    hide-details
                  ></v-text-field
                >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Return Date</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      outlined flat class="txtfield2" dense
                        hide-details=""
                        color="#b234a9"
                        append-icon="mdi-calendar-multiselect"
                        readonly label="Date"
                        v-model="returnDate"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="from = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="returnDate"
                      color="#571964"
                      @change="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Melt(gm)</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                          @input="cal2()"
                    type="number"
                    v-model="acidingMeltWt"
                    outlined flat class="txtfield2"
                    color="#b234a9"
                    placeholder="Melt Weight"
                    dense
                    hide-details
                  ></v-text-field
                >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Dust(gm)</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                          @input="cal2()"
                    type="number"
                    v-model="acidingDust"
                    outlined flat class="txtfield2"
                    color="#b234a9"
                    placeholder="Dust Weight "
                    dense
                    hide-details
                  ></v-text-field
                >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Touch of test piece(%)</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                    type="number"
                    v-model="touchoftestpiece"
                    outlined flat class="txtfield2"
                    color="#b234a9"
                    placeholder="Touch of test piece(%)"
                    dense
                    hide-details
                  ></v-text-field
                >
                        </v-flex>
                      </v-layout>
                    </v-flex> 
                    <v-flex xs6><v-spacer></v-spacer></v-flex>
                    <v-flex xs6 pt-7 px-4>
                     
                      <v-btn
                        type="submit"
                        color="#3F053C"
                        dark
                        block
                        @click="validate()"
                        ><v-icon>mdi-plus</v-icon>Add</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap pb-4 justify-end>
                <v-spacer></v-spacer>
                <v-flex xs2 text-right>
                  <v-layout wrap px-4 justify-end fill-height>
                    <v-flex xs12 text-center align-self-end class="buttons1">
                      <v-btn
                        type="submit"
                        color="#3F053C"
                        dark
                        block
                        @click="validate()"
                        ><v-icon>mdi-plus</v-icon>Add</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout> -->
            </v-card>
          </v-form>
        </v-dialog>
                  </v-card>
                </v-flex>
              </v-layout>
            </div>
          </template>
          <script>
          import axios from "axios";
          export default {
            props: ["title2"],
            data() {
              return {
                appLoading: false,
                ServerError: false,
                showsnackbar: false,
                timeout: 2000,
                msg: null,
                page: 1,
      Pagelength: null,
      addcat:false,
      menu2:false,
      addAciding:false,
      batchNo:"",
      batchCode:"",
      beforeAciding:"",
      acidingDate:"",
      testPiece:"",
      acidingTouchPercent:"",
      afterAcidingWt:"",
      acidingBoardingRate:"",
      returnDate:"",
      acidingMeltWt:"",
      acidingDust:"",
      touchoftestpiece:"",
                juwellaryId: localStorage.getItem("juwellaryId"),
              taxType:localStorage.getItem("GSTstatus"),
              date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
                List: [],
              };
            },
            mounted() {
              this.getBatch();
            },
            methods: {
              getAcidingDetails(items){
                this.batchCode=items._id;
                this.batchNo=items.batchNumber;
                this.addAciding=true
                this.checktype(items._id);
              },
              cal2(){
                this.afterAcidingWt = this.beforeAciding;
                if(this.acidingMeltWt){
                  var newWeight= this.afterAcidingWt-this.acidingMeltWt;
                  this.afterAcidingWt = newWeight;
                }
                if(this.testPiece){
                  var newWeight2= this.afterAcidingWt-this.testPiece;
                  this.afterAcidingWt = newWeight2;
                }
                if(this.acidingDust){
                  var newWeight3= this.afterAcidingWt-this.acidingDust;
                  this.afterAcidingWt = newWeight3;
                }
                console.log("afterAcidingWt=",this.afterAcidingWt)
              },
              checktype(item) {
      console.log("item=", item);
      var singleobj = this.List.find((x) => x._id == item);
      console.log("singleobj==", singleobj);
      if (singleobj) {
        this.beforeAciding = singleobj.totalOldGoldNetWeight;
        console.log("beforeAciding=", this.beforeAciding);
        var acidingDate1 = singleobj.batchDate;
        this.acidingDate = this.formatDate(acidingDate1)
        console.log("acidingDate=", this.acidingDate);
      } else {
        console.log("singleobj not found");
      }
    },
    validate(){
      if (!this.batchCode) {
        this.msg = "Please enter batch code";
        this.showsnackbar = true;
      }else if (!this.acidingBoardingRate) {
        this.msg = "Bording rate cannot be empty ";
        this.showsnackbar = true;
      } else if (!this.beforeAciding) {
        this.msg = "Before aciding weight cannot be empty ";
        this.showsnackbar = true;
      } else if (!this.returnDate) {
        this.msg = "Please enter return date";
        this.showsnackbar = true;
      } else if (!this.acidingDate) {
        this.msg = "Please enter aciding date";
        this.showsnackbar = true;
      } 
      else if (!this.acidingMeltWt) {
        this.msg = "Please enter melt weight";
        this.showsnackbar = true;
      }  
      else if (!this.testPiece) {
        this.msg = "Please enter test piece weight";
        this.showsnackbar = true;
      } 
      //  else if (!this.acidingDust) {
      //   this.msg = "Please enter dust weight";
      //   this.showsnackbar = true;
      // } 
       else if (!this.acidingTouchPercent) {
        this.msg = "Please enter touch percentage";
        this.showsnackbar = true;
      }  else if (!this.touchoftestpiece) {
        this.msg = "Please enter touch of test piece";
        this.showsnackbar = true;
      } 
      else if (!this.afterAcidingWt) {
        this.msg = "After aciding weight cannot be empty ";
        this.showsnackbar = true;
      }else if (this.afterAcidingWt > this.beforeAciding) {
        this.msg = "Thankam weight cannot be greater than aciding gold ";
        this.showsnackbar = true;
      }else {
        
        this.addBatch();
      }
    },
    addBatch() {
      axios({
        url: "/acidingdata/add",
        method: "POST",
        data: {
          batchNumber: this.batchCode,
          beforeacidingWeight: this.beforeAciding,
          acidingDate: this.acidingDate,
          thankamReturnDate: this.returnDate,
          testPiece: this.testPiece,
          touch: this.acidingTouchPercent,
          thankam: this.afterAcidingWt,
          thankamRate: this.acidingBoardingRate,
          meltingLoss: this.acidingMeltWt,
          dust: this.acidingDust,
          touchOfTestPiece: this.touchoftestpiece,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.batchCode = null;
            this.beforeAciding = null;
            this.acidingDate = null;
            this.returnDate = null;
            this.afterAciding = null;
            this.boardingRate = null;
            this.meltPercentage = null;
            this.$router.push('/AcidingReport');
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
              getBatch() {
                axios({
                  url: "/oldgoldbatch/list",
                  method: "get",
                  headers: {
                    "x-auth-token": localStorage.getItem("token"),
                  },
                  params:{
                    page: this.page,
          count: 20,
                  },
                })
                  .then((response) => {
                    this.appLoading = false;
                    if (response.data.status == true) {
                      this.List = response.data.data;
                      this.Pagelength = Math.ceil(response.data.count / 20);
                    } else {
                      this.msg = response.data.msg;
                      this.showsnackbar = true;
                    }
                  })
                  .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                  });
              },
              formatDate(item) {
                var dt = new Date(item);
                var day = dt.getDate();
                var year = dt.getFullYear();
                // var hours = dt.getHours();
                // var minutes = dt.getMinutes();
                dt = dt.toString();
                // var ampm = hours >= 12 ? "pm" : "am";
                // hours = hours % 12;
                // hours = hours ? hours : 12;
                // minutes = minutes < 10 ? "0" + minutes : minutes;
                var strTime = day + " " + dt.slice(4, 7) + " " + year;
                //  +
                // " , " +
                // hours +
                // ":" +
                // minutes +
                // " " +
                // ampm;
          
                return strTime;
              },
            },
          };
          </script>
          